import React, { useState } from 'react'
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
  const [isOpen, setOpen]=useState(false)
  return (
    <>
      <section className="about-area sec-mar-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 or-2 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <span>Get To Know</span>
                <h2>About US</h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>TCS DEV</h3>
                <p>Step into the realm of TCS DEV, where we are the architects of digital dreams. We don't just build websites we sculpt digital symphonies, cast social media spells, and conjure desktop marvels. Immerse yourself in our enchanting narrative, where every click is a magical journey. Your digital odyssey begins here. Welcome to TCS DEV.</p>
                <div className={`${"company-since"} ${props.layoutClass}`}>
                  <div className="company-logo">
                    {/* <img src={process.env.PUBLIC_URL + "/img/logo.png"} alt="about-images" style={{width: "50px"}} /> */}
                  </div>
                  <strong>#1</strong>
                  <h4>Technology Computing and Solution <span>Since 2022.</span></h4>
                </div>
              </div>
            </div>
            <div className="col-lg-6 or-1 wow animate fadeIn" data-wow-delay="200ms" data-wow-duration="1500ms">
              <div className="about-right">
                <div className="banner-1">
                  <img src={process.env.PUBLIC_URL + '/img/why-2.jpg'} alt="about-images" />
                </div>
                <div className="banner-2">
                  <img src={process.env.PUBLIC_URL + '/img/why-1.jpg'}  alt="about-images" />
                  <div className="banner2-inner">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>  
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          
          isOpen={isOpen}
          videoId="IUN664s7N-c"
          autoplay = {1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment> 
    </>
  )
}

export default AboutArea