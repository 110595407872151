import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import projectsData from "../../../data/data";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import SidebarWidget from './SidebarWidget';
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

function ProjectDetailsContent() {
    const projectSlider ={
        loop: true,
        slidesPerView: 1,
        navigation: {
            nextEl: ".swiper-button-next-c",
            prevEl: ".swiper-button-prev-c",
        } 
    }
    const { id } = useParams();
  const [project, setProject] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Find the project with the given ID from the imported data
    const selectedProject = projectsData.find(project => project.id === parseInt(id));

    if (selectedProject) {
      setProject(selectedProject);
      setLoading(false);
    } else {
      console.error(`Project with ID ${id} not found.`);
      setLoading(false);
    }
  }, [id]);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
     <div className="project-details-content">
        <div className="project-thumb">
          <img src={process.env.PUBLIC_URL + project.image} alt="mages" />
          <div className="tag">
            <Link to={`${process.env.PUBLIC_URL}/project-details`} onClick={window.scrollTo({top:0,behavior:"smooth"})}>{project.category}</Link>
          </div>
        </div>
        <h3>{project.title}</h3>
        <p>{project.description}</p>
        <div className="clinet-need">
          <h4>Client Needs</h4>
          <p>{project.clientNeeds}</p>
        </div>

        <div>
        <div className="sidebar-widget mt-4">
          <div className="client-box">
            <span>Company:</span>
            <h5>{project.company}</h5>
          </div>
          <div className="client-box">
            <span>Url:</span>
            <h5><a href={project.url}>{project.url}</a></h5>
          </div>
          <div className="client-box">
            <span>Location:</span>
            <h5>{project.location}</h5>
          </div>
          <div className="client-box">
            <span>Project Type:</span>
            <h5>{project.type}</h5>
          </div>
          <div className="client-box">
            <span>Duration:</span>
            <h5>{project.duration}</h5>
          </div>
        </div>
        </div>
      </div>   
    </>
  )
}

export default ProjectDetailsContent