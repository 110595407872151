import React from 'react'
import { Link } from 'react-router-dom'

function PriceCard(props) {
    const scrollTop =()=>window.scrollTo({top:0,behavior:"smooth"})
  return (
    <>
      <div className={`${"price-box"}`}>
        <h3>{props.cartTitle}</h3>
        <span>{props.priceCatagory}</span>
        <strong>${props.price}</strong>
        <ul className="item-list">
          <li><i className="bi bi-check" />{props.item1}</li>
          <li><i className="bi bi-check" />{props.item2}</li>
          <li><i className="bi bi-check" />{props.item3}</li>
          <li><i className="bi bi-check" />{props.item4}</li>
          <li><i className="bi bi-check" />{props.item5}</li>
          <li><i className="bi bi-check" />{props.item6}</li>
        </ul>
        <div className="price-btn">
          <div className="line-1" />
          <div className="line-2" />
          <Link to={`${process.env.PUBLIC_URL}/contact`} onClick={scrollTop}>Pay Now</Link>
        </div>
      </div>   
    </>
  )
}

export default PriceCard