import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap(props) {
  return (
    <>
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className={`${"sec-title"} ${props.white}`}>
              <span>TCS DEV</span>
              <h2>Projects</h2>
              <p>
                Step into our gallery of magical projects, where we've turned dreams into digital reality. Each project is a spellbinding showcase of the wonders we've woven for our clients.
              </p>
            </div>
          </div>
          <ProjectCardFilter/>
        </div>
      </div>
    </>
  );
}

export default ProjectWrap;
