import React from 'react'

function SidebarWidget() {

  return (
    <>
        <div className="sidebar-widget">
          <div className="contact-info">
            <h3>Need help?</h3>
            <p>Our team is here to assist you! If you have any questions, concerns, or need support, feel free to reach out</p>
            <div className="cmpy-info">
              <div className="icon">
                <i className="fas fa-map-marker-alt" />
              </div>
              <div className="cnt">
                <h5>Location</h5>
                <p>Pristina, Kosovo</p>
              </div>
            </div>
            <div className="cmpy-info">
              <div className="icon">
                <i className="fas fa-phone-alt" />
              </div>
              <div className="cnt">
                <h5>Phone</h5>
                <a href="tel:+38344644022">+383 44 644 022</a>
                <a href="tel:+38349246606">+383 49 246 606</a>
              </div>
            </div>
            <div className="cmpy-info">
              <div className="icon">
                <i className="far fa-envelope" />
              </div>
              <div className="cnt">
                <h5>Email</h5>
                <a href="tell:info@example.com">contact@tcs-dev.com</a>
              </div>
            </div>
          </div>
        </div>
      </>
  )
}

export default SidebarWidget