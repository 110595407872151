import React from "react";
import ServiceCard from "./ServiceCard";

function ServiceCardWrap() {
  return (
    <>
      <div className="services-grid sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title">
            <span>Our Services</span>
              <h2>Services</h2>
              <p>
              We seamlessly merge captivating web development with strategic Social Media Management (SMM), creating a synergistic online presence that captivates, engages, and converts.
              </p>
            </div>
          </div>
          <div className="row g-4">
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={1} image="/img/icons/service-icon-1.png" title="Web Development" details="Whether you're establishing an online presence, launching a new product, or upgrading your current site, our web development services provide tailor-made solutions."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={2} image="/img/icons/service-icon-2.png" title="Social Media Management" details="In the ever-evolving landscape of social media, managing your online presence is crucial. Our Social Media Management services help you build and maintain a strong presence"/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={3}  image="/img/icons/service-icon-3.png" title="Desktop Programms" details="Empower your business operations with our desktop program development. Our custom solutions cater to your unique requirements,"/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={4} image="/img/icons/service-icon-4.png" title="Video Editing" details="Transform your ideas into visually stunning narratives with our professional video editing services. Whether it's corporate videos, promotional content, or creative projects"/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={5} image="/img/icons/service-icon-5.png" title="Graphic Design" details="Elevate your brand aesthetic with our graphic design services. From logos to marketing collateral, we create visually captivating designs that resonate with your audience."/>
          </div>
          <div class="col-md-6 col-lg-4 wow animate fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
              <ServiceCard serviceNumber={6} image="/img/icons/service-icon-6.png" title="Mobile Applications" details="Tap into the vast potential of the mobile market with our mobile application development services. Our team designs and develops innovative, user-friendly apps for both iOS and Android"/>
          </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceCardWrap;
