import React, { useReducer, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import data from "../../../data/data";
import projectImg1 from "../../../assets/img/project/project-img-1.png";
import projectImg2 from "../../../assets/img/project/project-img-2.png";
import projectImg3 from "../../../assets/img/project/project-img-3.png";
import projectImg4 from "../../../assets/img/project/project-img-4.png";
import projectImg5 from "../../../assets/img/project/project-img-5.png";
import projectImg6 from "../../../assets/img/project/project-img-6.png";
import projectImg7 from "../../../assets/img/project/project-img-7.png";
import projectImg8 from "../../../assets/img/project/project-img-8.png";
import projectImg9 from "../../../assets/img/project/project-img-9.png";
import projectImg10 from "../../../assets/img/project/project-img-10.png";
import projectImg11 from "../../../assets/img/project/project-img-11.png";
import projectImg12 from "../../../assets/img/project/project-img-12.png";
import projectImg13 from "../../../assets/img/project/project-img-13.png";
import projectImg14 from "../../../assets/img/project/project-img-14.png";
import projectImg15 from "../../../assets/img/project/project-img-15.png";

function ProjectCardFilter() {
  const [items, setItem] = useState(data);

  const imageMap = {
    1: projectImg1,
    2: projectImg2,
    3: projectImg3,
    4: projectImg4,
    5: projectImg5,
    6: projectImg6,
    7: projectImg7,
    8: projectImg8,
    9: projectImg9,
    10: projectImg10,
    11: projectImg11,
    12: projectImg12,
    13: projectImg13,
    14: projectImg14,
    15: projectImg15
    // Add entries for other project IDs
  };
 
  const filterItem = (catagoryitem) => {
    const updateItem = data.filter((curentelement) => {
      return curentelement.category === catagoryitem;
    });

    setItem(updateItem);
  };

  const initialState = { statementActive: "" };
  function reducer(state, action) {
    switch (action.type) {
      case "dataAll":
        setItem(data);
      
        return { statementActive: "dataAll" };
      case "UI":
        filterItem("UI");
        return { statementActive: "UI" };
      case "web":
        filterItem("web");
        return { statementActive: "web" };
      case "developing":
        filterItem("developing");
        return { statementActive: "developing" };
      case "Graphic":
        filterItem("smm");
        return { statementActive: "smm" };
      default:
        throw new Error();
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <ul className="isotope-menu">
            <li
              onClick={() => dispatch({ type: "dataAll" })}
              className={state.statementActive === "dataAll" ? "active" : ""}
            >
              All
            </li>
            {/* <li
              onClick={() => dispatch({ type: "UI" })}
              data-filter=".ui"
              className={state.statementActive === "UI" ? "active" : ""}
            >
              Social Media Managment
            </li> */}
            <li
              onClick={() => dispatch({ type: "web" })}
              data-filter=".web"
              className={state.statementActive === "web" ? "active" : ""}
            >
              Web Development
            </li>
            <li
              onClick={() => dispatch({ type: "Graphic" })}
              data-filter=".graphic"
              className={state.statementActive === "Graphic" ? "active" : ""}
            >
              Social Media Management
            </li>
          </ul>
        </div>
      </div>
      <div className="row g-4 project-items d-flex">
        {items.map((element) => {
          const { title, id, details } = element;
          const image = imageMap[id];

          return (
            <div key={id} className="col-md-6 col-lg-4 single-item">
              <div className="item-img">
                <Link to={`${process.env.PUBLIC_URL}/project-details/${id}`}>
                  <img src={image} alt="images" />
                </Link>
              </div>
              <div className="item-inner-cnt">
                <span>{title}</span>
                <h4>{details}</h4>
                <div className="view-btn">
                  <Link to={`${process.env.PUBLIC_URL}/project-details`}>
                    Project Details
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ProjectCardFilter;
