const data = [
    {
        id:1,
        title:"Si Trade",
        category: "web",
        details:"Front-end Web",
        company: "Si Export Import",
        duration: "1 week",
        url: "https://exportimport-si.com",
        location: "Tirana, Albania",
        type: "Front-end",
        description: "Si Trade is a web project focused on front-end development for Si Export Import. With a duration of one week, the project involves creating an engaging and responsive user interface. The website showcases the company's products and services in the field of import and export.",
        clientNeeds: "Client needs for Si Trade include the development of a modern and responsive front-end web design that reflects Si Export Import's brand identity. The website should present import-export services clearly, feature user-friendly navigation, and integrate the company's logo and visual elements for a cohesive brand experience.",
    },
    {
        id:2,
        title:"Profi Window",
        category: "web",
        details:"Front-end Web",
        company: "Profi Window",
        duration: "1 week",
        url: "https://profi-window.com/",
        location: "Tetovo, North Macedonia",
        type: "Front-end",
        description: "Profi Window's web project focuses on front-end web development. The one-week duration is dedicated to crafting a visually appealing and user-friendly website. The site highlights Profi Window's offerings and provides a seamless browsing experience.",
        clientNeeds: "For Profi Window, client needs involve the creation of a visually appealing and user-friendly front-end web interface. The website should prominently showcase Profi Window's products and services, with seamless navigation for a positive user experience. Integration of the company's branding elements is essential.",
    },
    {
        id:3,
        title:"Fix Gips",
        category: "web",
        details:"Front-end Web",
        company: "Fix Gips",
        duration: "1 week",
        url: "https://fixgips.ch/",
        location: "Zurich, Switzerland",
        type: "Front-end",
        description: "Fix Gips' web project is centered around front-end web development. Over the course of one week, the team is dedicated to creating an intuitive and visually appealing website. The site showcases Fix Gips' services and expertise in the industry.",
        clientNeeds: "Client needs for Fix Gips include the development of a professional and visually engaging front-end web design. The website should clearly present Fix Gips' services, with a focus on a user-friendly interface. Integration of the company's branding elements is important, and the completion of the project within a one-week timeframe is a key requirement.",
    },
    {
        id:4,
        title:"Giax GMBH",
        category: "web",
        details:"Front-end Web",
        company: "Giax GMBH",
        duration: "1 week",
        url: "https://giax-gmbh.ch/",
        location: "Zurich, Switzerland",
        type: "Front-end",
        description: "Giax GMBH's web project involves front-end web development. The team dedicates one week to design and develop a visually appealing website that represents Giax GMBH's brand. The site serves as a platform to showcase the company's offerings.",
        clientNeeds: "Client needs for Giax GMBH involve the development of a visually appealing and user-friendly front-end web design. The website should effectively showcase Giax GMBH's products and services, ensuring clear navigation for a positive user experience. Integration of the company's branding elements is crucial.",
    },
    {
        id:5,
        title:"Esa Plast",
        category: "web",
        details:"Front-end Web",
        company: "Esa Plast",
        duration: "1 week",
        url: "https://esaplast.net/",
        location: "Pristina, Kosovo",
        type: "Front-end",
        description: "Esa Plast's web project focuses on front-end web development. The one-week duration is dedicated to creating an attractive and user-friendly website that highlights Esa Plast's products and services in the field of plastic manufacturing.",
        clientNeeds: "For Esa Plast, client needs include the creation of a front-end web design that is both professional and visually engaging. The website should clearly present Esa Plast's products and services with a focus on user-friendly navigation. Integration of the company's branding elements is essential.",
    },
    {
        id:6,
        title:"Slowianka Kosova",
        category: "web",
        details:"E-commerce",
        company: "Slowianka Kosova",
        duration: "3 weeks",
        url: "https://slowiankanails-ks.com/",
        location: "Peje, Kosovo",
        type: "Full Stack - E-commerce",
        description: "Slowianka Kosova's web project is an e-commerce venture with a focus on full-stack development. The three-week duration involves creating a comprehensive online store for Slowianka Kosova's products. The website enables users to browse, select, and purchase items seamlessly.",
        clientNeeds: "Client needs for Slowianka Kosova involve the development of a comprehensive e-commerce website with a full-stack approach. The website should provide a seamless online shopping experience, featuring a user-friendly interface. Integration of branding elements and showcasing products effectively are priorities.",
    },
    {
        id:7,
        title:"Active Tuning",
        category: "web",
        details:"Front-end Web",
        company: "Active Tuning",
        duration: "1 week",
        url: "https://tuningactive.com/",
        location: "Tetovo, North Macedonia",
        type: "Front-end",
        description: "Active Tuning's web project is centered around front-end web development. The one-week duration is dedicated to creating a visually appealing and responsive website. The site showcases Active Tuning's products in the automotive tuning industry.",
        clientNeeds: "For Active Tuning, client needs include the creation of a visually appealing and responsive front-end web design. The website should effectively showcase Active Tuning's products in the automotive tuning industry. Clear navigation and integration of branding elements are important.",
    },
    {
        id:8,
        title:"Dent Med",
        category: "smm",
        details:"Social Media Management",
        company: "Dento Med",
        duration: "Monthly Subscription",
        url: "https://www.instagram.com/klinikadentomend/",
        location: "Peje, Kosovo",
        type: "Basic",
        description: "Dent Med's Social Media Management project focuses on enhancing the online presence through strategic social media activities.",
        clientNeeds: "Client needs for Dent Med involve the creation of high-quality social media designs that showcase the dental services offered. The designs should align with Dent Med's brand identity and engage the target audience. The project includes strategic planning for social media content and ensuring timely posting on various platforms.",
    },
    {
        id:9,
        title:"KSystems",
        category: "smm",
        details:"Social Media Management",
        company: "KSystems",
        duration: "Monthly Subscription",
        url: "https://www.instagram.com/ksystemsdb/",
        location: "Kumanovo, North Macedonia",
        type: "Silver",
        description: "KSystems' Social Media Management project aims to strengthen the online presence through the creation and posting of high-quality designs.",
        clientNeeds: "Client needs for KSystems involve the development of visually appealing social media designs that effectively communicate the brand's message. The designs should align with KSystems' brand identity and engage the target audience. Strategic content planning and timely posting are crucial for the success of this Social Media Management project.",
    },
    {
        id:10,
        title:"Tregukos",
        category: "smm",
        details:"Social Media Management",
        company: "Tregukos",
        duration: "Monthly Subscription",
        url: "https://www.instagram.com/tregukos_ks/",
        location: "Peje, Kosovo",
        type: "Silver",
        description: "Tregukos' Social Media Management project focuses on creating and posting high-quality designs to enhance the brand's visibility.",
        clientNeeds: "Client needs for Tregukos include the creation of visually appealing social media designs that effectively represent the brand. The designs should align with Tregukos' brand identity and engage the target audience. Timely posting and strategic content planning are essential components of this Social Media Management project.",
    },
    {
        id:11,
        title:"Team Pizza",
        category: "smm",
        details:"Social Media Management",
        company: "Si Export Import",
        duration: "Monthly Subscription",
        url: "#",
        location: "Pristina, Kosovo",
        type: "Silver",
        description: "Team Pizza's Social Media Management project aims to elevate the brand's online presence through the creation and posting of high-quality designs.",
        clientNeeds: "Client needs for Team Pizza involve the development of visually appealing social media designs that effectively represent the brand. The designs should align with Team Pizza's brand identity and engage the target audience. Timely posting and strategic content planning are crucial for this Social Media Management project.",
    },
    {
        id:12,
        title:"Doll House",
        category: "smm",
        details:"Social Media Management",
        company: "Doll House",
        duration: "Monthly Subscription",
        url: "#",
        location: "Tirana, Albania",
        type: "Bronze",
        description: "Doll House's Social Media Management project focuses on creating and posting high-quality designs to enhance the brand's visibility on social media platforms.",
        clientNeeds: "Client needs for Doll House include the creation of visually appealing social media designs that effectively represent the brand. The designs should align with Doll House's brand identity and engage the target audience. Timely posting and strategic content planning are essential components of this Social Media Management project.",
    },
    {
        id:13,
        title:"Dream Wood",
        category: "smm",
        details:"Social Media Management",
        company: "Dream Wood",
        duration: "Monthly Subscription",
        url: "https://www.instagram.com/dream_woodks/",
        location: "Prizren, Kosovo",
        type: "Bronze",
        description: "Dream Wood's Social Media Management project focuses on creating and posting high-quality designs to enhance the brand's visibility on social media platforms.",
        clientNeeds: "Client needs for Dream Wood include the creation of visually appealing social media designs that effectively represent the brand. The designs should align with Dream Wood's brand identity and engage the target audience. Timely posting and strategic content planning are essential components of this Social Media Management project.",
    },
    {
        id:14,
        title:"Tregukos",
        category: "web",
        details:"Front-end Web",
        company: "Tregukos",
        duration: "2 weeks",
        url: "https://tregukos.com/",
        location: "Peje, Kosovo",
        type: "Front-end",
        description: "Tregukos web project focuses on front-end web development, with a duration of two weeks. The primary objective is to create a visually appealing and user-friendly website for Tregukos, highlighting their products. The website will serve as an online platform to engage with their audience and showcase their brand.",
        clientNeeds: "For the Tregukos project, client needs involve the development of a modern, visually appealing, and user-friendly front-end web design. The website should effectively showcase Tregukos products, enhancing user engagement. Clear navigation and integration of the company's branding elements are essential aspects."
    },
    {
        id:15,
        title:"Urban Tech",
        category: "web",
        details:"Front-end Web",
        company: "Esa Plast",
        duration: "1 week",
        url: "https://urbantech-ks.com/",
        location: "Pristina, Kosovo",
        type: "Front-end",
        description: "Urban Tech's web project involves front-end web development with a duration of one week. The main focus is on creating a visually appealing website for Urban Tech, emphasizing their technological solutions and services. The website will serve as a digital presence for Urban Tech, providing information about their offerings.",
        clientNeeds: "For Urban Tech, client needs include the development of a sleek and modern front-end web design. The website should effectively highlight Urban Tech's technological solutions, ensuring a positive user experience with clear navigation."
    },
    // {
    //     id:7,
    //     title:"3D Design",
    //     image:"img/project/project-5.jpg",
    //     category: "Graphic",
    //     details:"Low Poly Base Mesh",
    // },
    // {
    //     id:8,
    //     title:"3D Design",
    //     image:"img/project/project-1.jpg",
    //     category: "Graphic",
    //     details:"Low Poly Base Mesh",
    // },
    // {
    //     id:9,
    //     title:"UI Kit",
    //     image:"img/project/project-2.jpg",
    //     category: "UI",
    //     details:"Low Poly Base Mesh",
    // },
    // {
    //     id:10,
    //     title:"UI Kit",
    //     image:"img/project/project-4.jpg",
    //     category: "UI",
    //     details:"Low Poly Base Mesh",
    // },
    // {
    //     id:11,
    //     title:"App",
    //     image:"img/project/project-3.jpg",
    //     category: "App",
    //     details:"Mobile Crypto Wallet",
    // },
    // {
    //     id:12,
    //     title:"App",
    //     image:"img/project/project-1.jpg",
    //     category: "App",
    //     details:"Mobile Crypto Wallet",
    // },
];

export default data;